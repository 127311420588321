<template>
  <BaseButton
    class="p-5 is-color4"
    @click.prevent="emitUpdateForm('saveService', true)"
    size="large"
    action="tercery"
    :disabled="loading || disableQuoteByStatus"
  >
    <i slot="icon" class="is-size mr-5">
      <BaseButtonLock v-if="disableQuoteByStatus" />
      <IconCheck v-else class="icon is-small" />
    </i>
    <p slot="text">Save</p>
  </BaseButton>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BottomButtonSaveSideBar",
  computed: {
    ...mapGetters({
      disableQuoteByStatus: "itinerary/getDisableQuoteByStatus",
      loading: "utils/getLoading",
    }),
  },
  methods: {
    emitUpdateForm(key, value) {
      this.$emit("update", [key, value]);
    },
  },
};
</script>
