<template>
  <section class="is-relative">
    <CustomDotAlert
      :displayAlert="hasViewSalePricePermission && hasNewPriceAvailable"
      :tooltip="'Service price is outdated'"
      :customStyle="`left: ${isAccommodationPackageService ? '58%' : '50%'}`"
    />
    <b-tabs
      :value="defaultOpenedTab"
      position="is-centered"
      class="block mb-0"
      @input="updateDefaultOpenedTab"
    >
      <EditServiceSideBarTabsInfo
        :formItineraryService="formItineraryService"
        :itineraryService="serviceItinerary"
        :validateFunctionExecution="validateFunctionExecution"
        @update="emitUpdateForm"
      />
      <EditServiceSideBarTabsRoom
        :formItineraryService="formItineraryService"
        :serviceLocation="serviceLocation"
        :handleAutomaticPriceCalculate="handleAutomaticPriceCalculate"
        :handleServiceFirstTime="handleServiceFirstTime"
        @update="emitUpdateForm"
      />
      <EditServiceSideBarTabsPricing
        v-if="hasViewSalePricePermission"
        :formItineraryService="formItineraryService"
        :originalServicePrice="originalServicePrice"
        :open="open"
        :automaticPriceCalculate="automaticPriceCalculate"
        :handleAutomaticPriceCalculate="handleAutomaticPriceCalculate"
        :serviceFirstTime="serviceFirstTime"
        :handleServiceFirstTime="handleServiceFirstTime"
        @update="emitUpdateForm"
      />
      <EditServiceSideBarTabsPaxs
        v-on:handled-toggle-switch="handledToggleSwitch"
        :itinerary="itinerary"
        :formItineraryService="formItineraryService"
        :passengers="passengers"
        :passengerOptions="passengerOptions"
        @update="emitUpdateForm"
      />
    </b-tabs>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import EditServiceSideBarTabsInfo from "@/components/_shared/EditServiceSideBarTabsInfo.vue";
import EditServiceSideBarTabsRoom from "@/components/_shared/EditServiceSideBarTabsRoom.vue";
import EditServiceSideBarTabsPricing from "@/components/_shared/EditServiceSideBarTabsPricing.vue";
import EditServiceSideBarTabsPaxs from "@/components/_shared/EditServiceSideBarTabsPaxs.vue";
import { serviceHelper } from "@/utils/service";
import { PERMISSION } from "@/constants";
import CustomDotAlert from "@/components/_shared/CustomDotAlert.vue";

export default {
  name: "EditServiceSideBarTabs",
  components: {
    EditServiceSideBarTabsInfo,
    EditServiceSideBarTabsRoom,
    EditServiceSideBarTabsPricing,
    EditServiceSideBarTabsPaxs,
    CustomDotAlert,
  },
  props: {
    updateServiceViewState: {
      type: Function,
      default: () => {},
    },
    defaultOpenedTab: {
      type: Number,
      default: () => 1,
    },
    formItineraryService: {
      type: Object,
      default: () => {},
    },
    serviceLocation: {
      type: Array,
      default: () => [],
    },
    serviceItinerary: {
      type: Object,
      default: () => {},
    },
    passengers: {
      type: [Array, Object],
      default: () => [],
    },
    open: {
      type: Boolean,
      default: () => false,
    },
    handleAutomaticPriceCalculate: {
      type: Function,
      default: () => {},
    },
    automaticPriceCalculate: {
      type: Boolean,
      default: true,
    },
    serviceFirstTime: {
      type: Boolean,
      default: false,
    },
    handleServiceFirstTime: {
      type: Function,
      default: () => {},
    },
    passengerOptions: {
      type: Array,
      default: () => [],
    },
    validateFunctionExecution: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      itinerary: "itinerary/getItinerary",
      authData: "auth/getAuthData",
    }),
    hasViewSalePricePermission() {
      return serviceHelper.hasAnyViewPermissions(
        this.authData,
        this.itinerary,
        [PERMISSION.VIEW_SALE_PRICE]
      );
    },
    hasNewPriceAvailable() {
      return this.originalServicePrice !== this.formItineraryService?.price;
    },
    originalServicePrice() {
      const serviceTypeId = this.formItineraryService.id_service_type;
      if (serviceHelper.isAccommodationPackageService(serviceTypeId)) {
        const nights = serviceHelper.isPackageService(serviceTypeId)
          ? 1
          : this.formItineraryService.days;

        return this.formItineraryService.room.reduce((sum, room) => {
          return sum + room.new_price_unit * room.qty * nights;
        }, 0);
      }

      if (!this.formItineraryService?.prices_costs_margins) return 0;
      if (!this.formItineraryService?.prices_costs_margins?.price) return 0;
      return this.formItineraryService.prices_costs_margins?.price;
    },
    isAccommodationPackageService() {
      return serviceHelper.isAccommodationPackageService(
        this.formItineraryService?.id_service_type
      );
    },
  },
  methods: {
    handledToggleSwitch: function (value) {
      this.$emit("handled-toggle-switch", value);
    },
    emitUpdateForm(value) {
      this.$emit("update", value);
    },
    updateDefaultOpenedTab(value) {
      this.updateServiceViewState("defaultOpenedTab", value);
    },
  },
};
</script>
